export const ADD_PRESTATION = "ADD_PRESTATION";
export const REMOVE_PRESTATION = "REMOVE_PRESTATION";
export const STORE_CATALOG = "STORE_CATALOG";
export const STORE_CURRENT_PRESTATION = "STORE_CURRENT_PRESTATION";
export const STORE_CURRENT_NAVIGATION_INDEX = "STORE_CURRENT_NAVIGATION_INDEX";
export const STORE_ADDRESS = "STORE_ADDRESS";
export const STORE_APPOINTMENT = "STORE_APPOINTMENT";
export const STORE_BOOKING = "STORE_BOOKING";
export const INIT_PRESTATIONS = "INIT_PRESTATIONS";
export const INIT_APPOINTMENT = "INIT_APPOINTMENT";
export const INIT_ADDRESS = "INIT_ADDRESS";
